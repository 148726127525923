import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name signature
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQuMjE4IDcuMTgzYTIuNSAyLjUgMCAxIDAtMy43MTItMi4zNTRjLS4zNDkgMi4yOTUtLjg1MyAxMi4yMTctNS4wMDYgMTIuMjE3YTEgMSAwIDAgMSAwLTUuMDkxYzQuNTA5LjAzIDguNTE2IDEuNjc2IDguNTE2IDQuMjIxYTEgMSAwIDAgMCAuNzgxLjgwM2wyLjQyOS4wMTVhMSAxIDAgMCAwIDEuMDA2LTF2LS40YS41LjUgMCAwIDEgLjgzOC0uMzY4TDIxIDE3IiAvPgogIDxwYXRoIGQ9Ik0zIDIxaDE4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/signature
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Signature: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M14.218 7.183a2.5 2.5 0 1 0-3.712-2.354c-.349 2.295-.853 12.217-5.006 12.217a1 1 0 0 1 0-5.091c4.509.03 8.516 1.676 8.516 4.221a1 1 0 0 0 .781.803l2.429.015a1 1 0 0 0 1.006-1v-.4a.5.5 0 0 1 .838-.368L21 17',
      },
    ],
    ['path', { d: 'M3 21h18' }],
  ],
];

export default Signature;
